import React from 'react'
import { Helmet } from "react-helmet"
import Header from '../components/Header'
import Footer from '../components/Footer'

const NotFoundPage = () => {
    return(
        <React.Fragment>
            <Helmet
                htmlAttributes={{
                    lang: 'en',
                }}
            >
                <meta charSet="utf-8" />
                <title>Amol Garhwal | Page Not Found</title>
                <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600;800;900&display=swap"/>
                <meta name="description" 
                content="The page you're trying to reach doesn't exist." />
            </Helmet>
            <Header />
                <div className="container ck-container-not-found">
                    <div className="row">
                        <div className="col-md-7">
                            <h1 className="ck-error-heading">
                                The page you're trying to visit doesn't exist.
                            </h1>
                        </div>
                        <div className="col-md-5">
                            <h1 className="ck-error-code">
                                ERROR 404
                            </h1>
                        </div>
                    </div>
                </div>
            <Footer />
        </React.Fragment>
    )
}
  
export default NotFoundPage;